<template>
<div v-if="flag">
  <div v-if="tv">
    <div class="flex justify-center gap-x-6">
      <button 
        id="huBtn" 
        class="font-body text-2xl border border-transparent rounded-2xl p-3.5 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('hu')"
        :class="huActive ? 'bg-twine bg-opacity-20 text-white' : 'text-redDamask'">
        <img src="../assets/hu.png" alt="hungarian" class="mb-3.5" :class="huActive ? 'border-2 rounded-full border-redDamask p-0.5' : ''">
        HU
      </button>
      <button 
        id="roBtn" 
        class="font-body text-2xl border border-transparent rounded-2xl p-3.5 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('ro')"
        :class="roActive ? 'bg-twine bg-opacity-20 text-white' : 'text-redDamask'">
        <img src="../assets/ro.png" alt="romanian" class="mb-3.5" :class="roActive ? 'border-2 rounded-full border-redDamask p-0.5' : ''">
        RO
      </button>
      <button 
        id="enBtn" 
        class="font-body text-2xl border border-transparent rounded-2xl p-3.5 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('en')"
        :class="enActive ? 'bg-twine bg-opacity-20 text-white' : 'text-redDamask'">
        <img src="../assets/en.png" alt="english" class="mb-3.5" :class="enActive ? 'border-2 rounded-full border-redDamask p-0.5' : ''">
        EN
      </button>
      <button 
        id="deBtn" 
        class="font-body text-2xl border border-transparent rounded-2xl p-3.5 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('de')"
        :class="deActive ? 'bg-twine bg-opacity-20 text-white' : 'text-redDamask'">
        <img src="../assets/de.png" alt="" class="mb-3.5" :class="deActive ? 'border-2 rounded-full border-redDamask p-0.5' : ''">
        DE
      </button>
    </div>
  </div>
  <div v-else-if="tablet">
    <div class="flex justify-center gap-x-4">
      <button 
        id="huBtn" 
        class="font-body text-bodyL border border-transparent rounded p-3 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('hu')"
        :class="huActive ? 'bg-twine bg-opacity-20 text-white' : 'text-redDamask'">
        <img src="../assets/hu.png" alt="hungarian" class="mb-3 w-14 h-14" :class="huActive ? 'border-2 rounded-full border-redDamask p-0.5' : ''">
        HU
      </button>
      <button 
        id="roBtn" 
        class="font-body text-bodyL border border-transparent rounded p-3 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('ro')"
        :class="roActive ? 'bg-twine bg-opacity-20 text-white' : 'text-redDamask'">
        <img src="../assets/ro.png" alt="romanian" class="mb-3 w-14 h-14" :class="roActive ? 'border-2 rounded-full border-redDamask p-0.5' : ''">
        RO
      </button>
      <button 
        id="enBtn" 
        class="font-body text-bodyL border border-transparent rounded p-3 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('en')"
        :class="enActive ? 'bg-twine bg-opacity-20 text-white' : 'text-redDamask'">
        <img src="../assets/en.png" alt="english" class="mb-3 w-14 h-14" :class="enActive ? 'border-2 rounded-full border-redDamask p-0.5' : ''">
        EN
      </button>
      <button 
        id="deBtn" 
        class="font-body text-bodyL border border-transparent rounded p-3 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('de')"
        :class="deActive ? 'bg-twine bg-opacity-20 text-white' : 'text-redDamask'">
        <img src="../assets/de.png" alt="" class="mb-3 w-14 h-14" :class="deActive ? 'border-2 rounded-full border-redDamask p-0.5' : ''">
        DE
      </button>
    </div>
  </div>
  <div v-else-if="mobile">
    <div class="flex justify-center gap-x-4">
      <button 
        id="huBtn" 
        class="font-body text-bodyL border border-transparent rounded p-3 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('hu')"
        :class="huActive ? 'dark:text-white text-sepiaBlack' : 'text-redDamask'">
        <img src="../assets/hu.png" alt="hungarian" class="mb-2" :class="huActive ? 'border-2 rounded-full dark:border-white border-sepiaBlack p-0.5 w-10 h-10' : 'border-2 rounded-full border-redDamask p-0.5 w-8 h-8'">
        HU
      </button>
      <button 
        id="roBtn" 
        class="font-body text-bodyL border border-transparent rounded p-3 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('ro')"
        :class="roActive ? 'dark:text-white text-sepiaBlack' : 'text-redDamask'">
        <img src="../assets/ro.png" alt="romanian" class="mb-2" :class="roActive ? 'border-2 rounded-full dark:border-white border-sepiaBlack p-0.5 w-10 h-10' : 'border-2 rounded-full border-redDamask p-0.5 w-8 h-8'">
        RO
      </button>
      <button 
        id="enBtn" 
        class="font-body text-bodyL border border-transparent rounded p-3 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('en')"
        :class="enActive ? 'dark:text-white text-sepiaBlack' : 'text-redDamask'">
        <img src="../assets/en.png" alt="english" class="mb-2" :class="enActive ? 'border-2 rounded-full dark:border-white border-sepiaBlack p-0.5 w-10 h-10' : 'border-2 rounded-full border-redDamask p-0.5 w-8 h-8'">
        EN
      </button>
      <button 
        id="deBtn" 
        class="font-body text-bodyL border border-transparent rounded p-3 outline-none focus:outline-none active:outline-none" 
        v-on:click="langSelect('de')"
        :class="deActive ? 'dark:text-white text-sepiaBlack' : 'text-redDamask'">
        <img src="../assets/de.png" alt="" class="mb-2" :class="deActive ? 'border-2 rounded-full dark:border-white border-sepiaBlack p-0.5 w-10 h-10' : 'border-2 rounded-full border-redDamask p-0.5 w-8 h-8'">
        DE
      </button>
    </div>
  </div>
</div>
<div v-else>
  <div class="flex justify-center">
    <button 
      id="huBtn" 
      class="font-body text-bodyL border border-transparent rounded-full w-10 h-10 dark:text-white text-sepiaBlack hover:bg-redDamask active:bg-redDamask hover:bg-opacity-20 active:bg-opacity-20 pt-1 outline-none focus:outline-none active:outline-none" 
      v-on:click="langSelect('hu')"
      :class="huActive ? 'bg-redDamask bg-opacity-20 border-redDamask' : ''">HU
    </button>
    <button 
      id="roBtn" 
      class="font-body text-bodyL border border-transparent rounded-full w-10 h-10 dark:text-white text-sepiaBlack hover:bg-redDamask active:bg-redDamask hover:bg-opacity-20 active:bg-opacity-20 pt-1 outline-none focus:outline-none active:outline-none" 
      v-on:click="langSelect('ro')"
      :class="roActive ? 'bg-redDamask bg-opacity-20 border-redDamask' : ''">RO
    </button>
    <button 
      id="enBtn" 
      class="font-body text-bodyL border border-transparent rounded-full w-10 h-10 dark:text-white text-sepiaBlack hover:bg-redDamask active:bg-redDamask hover:bg-opacity-20 active:bg-opacity-20 pt-1 outline-none focus:outline-none active:outline-none" 
      v-on:click="langSelect('en')"
      :class="enActive ? 'bg-redDamask bg-opacity-20 border-redDamask' : ''">EN
    </button>
    <button 
      id="deBtn" 
      class="font-body text-bodyL border border-transparent rounded-full w-10 h-10 dark:text-white text-sepiaBlack hover:bg-redDamask active:bg-redDamask hover:bg-opacity-20 active:bg-opacity-20 pt-1 outline-none focus:outline-none active:outline-none" 
      v-on:click="langSelect('de')"
      :class="deActive ? 'bg-redDamask bg-opacity-20 border-redDamask' : ''">DE
    </button>
  </div>
</div>
</template>

<script>
export default {
  name: 'Language',
  props: {
    language: {
      type: String,
      default: window.localStorage.language
    },
    tv: {
      type: Boolean,
      default: false
    },
    tablet: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    },
    flag: {
      type: Boolean,
      default: false
    } 
  },
  data() {
    return {
      currentLang: this.language,
      huActive: this.language == 'hu' ? true : false,
      roActive: this.language == 'ro' ? true : false,
      enActive: this.language == 'en' ? true : false,
      deActive: this.language == 'de' ? true : false,
    }
  },
  methods: {
    langSelect: function(lang) {
      if(lang !== this.currentLang) {
        this.$emit('langchanged', lang);
        switch (lang) {
          case 'hu' :
            this.huActive = true;
            this.roActive = false;
            this.enActive = false;
            this.deActive = false;
            break;
          case 'ro' :
            this.huActive = false;
            this.roActive = true;
            this.enActive = false;
            this.deActive = false;
            break;
          case 'en' :
            this.huActive = false;
            this.roActive = false;
            this.enActive = true;
            this.deActive = false;
            break;
          case 'de' :
            this.huActive = false;
            this.roActive = false;
            this.enActive = false;
            this.deActive = true;
            break;
          default:
            break;
        }
        window.localStorage.language = lang;
        localStorage.setItem('language', lang);
        this.$i18n.locale = lang;
        this.currentLang = lang;
      }
    }
  },
  watch: {
    language: function(newLanguage) { // watch it
      this.currentLang = newLanguage;
      this.huActive = newLanguage == 'hu' ? true : false;
      this.roActive = newLanguage == 'ro' ? true : false;
      this.enActive = newLanguage == 'en' ? true : false;
      this.deActive = newLanguage == 'de' ? true : false;
    },
  }
}
</script>
